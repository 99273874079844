<template>
  <BaseContent activePath="/clients">
    <template v-slot:header>
      <b>
        <i class="fa fa-users"></i> Clients
      </b>
    </template>

    <b-container fluid>
      <!-- User Interface controls -->
      <b-row>
        <b-col sm="6" md="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Type to Search"
                debounce="500"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="6" md="6" class="my-1">
          <router-link class="btn mr-1 btn-secondary btn-sm" to="/client">New Client</router-link>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="6" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>

      <b-card title="Clients">
        <b-table
          hover
          :items="dataProvider"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
          small
          striped
          responsive="sm"
        >
          <template v-slot:row-details="row">
            <template v-for="(paybill,index) in row.item.client_paybills">
              <b-card :key="index">
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right">
                    <b>Paybill Type:</b>
                  </b-col>
                  <b-col>{{ paybill.paybill_type }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right">
                    <b>Application name:</b>
                  </b-col>
                  <b-col>{{ paybill.api_application_name }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right">
                    <b>Is Active:</b>
                  </b-col>
                  <b-col>{{ paybill.client_paybill_status }}</b-col>
                </b-row>

                <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
              </b-card>
            </template>
          </template>

          <template v-slot:cell(actions)="row">
            <router-link class="btn mr-1 btn-secondary btn-sm" :to="getEditPath(row.item)">Edit</router-link>&nbsp;
            <b-button
              size="sm"
              @click="row.toggleDetails"
            >{{ row.detailsShowing ? 'Hide' : 'Show' }} Details</b-button>
          </template>
        </b-table>
      </b-card>
    </b-container>
  </BaseContent>
</template>

<script>
// @ is an alias to /src
import BaseContent from "@/components/BaseContent.vue";

import axios from "axios";

import store from "@/store";

export default {
  name: "Clients",
  data() {
    return {
      fields: [
        { key: "client_name", sortable: true },
        { key: "consumer_key", sortable: true },
        { key: "allowed_transaction_types", sortable: true },
        { key: "client_status", sortable: false },
        { key: "actions" }
      ],
      rows: [],
      totalRows: 100,
      currentPage: 1,
      perPage: 10,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null
    };
  },
  methods: {
    dataProvider: function(ctx) {
      const promise = axios
        .create({
          baseURL: store.baseURL,
          auth: {
            username: store.getUsername(),
            password: store.getPassword()
          }
        })
        .get(
          "/api/v1/clients?offset=" +
            (ctx.currentPage - 1) +
            "&page_size=" +
            ctx.perPage +
            (ctx.filter != null ? "&q=" + ctx.filter : "") +
            (String(ctx.sortBy) != String("")
              ? "&sort=" +
                ctx.sortBy +
                "&sortd=" +
                (ctx.sortDesc ? "desc" : "asc")
              : "")
        );

      // Must return a promise that resolves to an array of items
      return promise.then(data => {
        // Pluck the array of items off our axios response
        const items = data.data.results;
        // Must return an array of items or an empty array if an error occurred

        this.rows = items || [];

        return items || [];
      });
    },
    getEditPath(item) {
      return "/client/" + item.client_id;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = 100 || filteredItems.length;
      // this.currentPage = 1;
    }
  },
  components: {
    BaseContent
  }
};
</script>
