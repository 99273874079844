<template>
  <BaseContent activePath="/settings">
    <template v-slot:header>
      <b>
        <i class="fa fa-cog"></i> Setting
      </b>
    </template>

    <div v-if="loading" class="loading">Loading...</div>

    <div v-if="error" class="error">{{ error }}</div>

    <b-card :title="'Setting: '+ this.form.setting_profile +'[' + this.form.setting_name+']'">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group
          id="input-group-setting_profile"
          label="Profile:"
          label-for="input-setting_profile"
        >
          <b-form-input
            id="input-setting_profile"
            v-model="form.setting_profile"
            type="text"
            readonly
            required
            placeholder="Enter Profile"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-setting_name" label="Name:" label-for="input-setting_name">
          <b-form-input
            id="input-setting_name"
            v-model="form.setting_name"
            type="text"
            readonly
            required
            placeholder="Enter Name"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-setting_value" label="Value:" label-for="input-setting_value">
          <b-form-input
            id="input-setting_value"
            v-model="form.setting_value"
            type="text"
            required
            placeholder="Enter Value"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary">Submit</b-button>&nbsp;
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-form>
    </b-card>
  </BaseContent>
</template>

<script>
// @ is an alias to /src
import BaseContent from "@/components/BaseContent.vue";

import axios from "axios";

import store from "@/store";

export default {
  name: "Setting",
  props: ["setting_id"],
  components: {
    BaseContent
  },
  data() {
    return {
      form: {
        setting_id: null,
        setting_profile: null,
        setting_name: null,
        setting_value: null,
        record_version: null
      },
      show: true,
      loading: false,
      response: null,
      error: null
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      //save
      axios
        .create({
          baseURL: store.baseURL,
          auth: {
            username: store.getUsername(),
            password: store.getPassword()
          }
        })
        .put("/api/v1/settings/" + this.setting_id, this.form)
        .then(response => {
          this.response = response.data;
          this.loading = false;

          this.form.setting_id = this.response.resource.setting_id;
          this.form.setting_profile = this.response.resource.setting_profile;
          this.form.setting_name = this.response.resource.setting_name;
          this.form.setting_value = this.response.resource.setting_value;
          this.form.record_version = this.response.resource.record_version;

          this.makeToast("Saved","Setting Saved","success");
        })
        .catch(e => {
          //missing setting, display error?
          this.error = e.toString();
          this.makeToast("Save Failed","Setting not Saved","warning");
        });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.setting_id = this.response.resource.setting_id;
      this.form.setting_profile = this.response.resource.setting_profile;
      this.form.setting_name = this.response.resource.setting_name;
      this.form.setting_value = this.response.resource.setting_value;
      this.form.record_version = this.response.resource.record_version;
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    makeToast(title, content, variant = null) {
      this.$bvToast.toast(content, {
        toaster:"b-toaster-top-center",
        title,
        variant,
        solid: true
      });
    },
    fetchData() {
      this.error = this.response = null;
      this.loading = true;
      //try access a resource
      axios
        .create({
          baseURL: store.baseURL,
          auth: {
            username: store.getUsername(),
            password: store.getPassword()
          }
        })
        .get("/api/v1/settings/" + this.setting_id)
        .then(response => {
          this.response = response.data;
          this.loading = false;

          this.form.setting_id = this.response.resource.setting_id;
          this.form.setting_profile = this.response.resource.setting_profile;
          this.form.setting_name = this.response.resource.setting_name;
          this.form.setting_value = this.response.resource.setting_value;
          this.form.record_version = this.response.resource.record_version;
        })
        .catch(e => {
          //missing setting, display error?
          this.error = e.toString();

          this.makeToast("Loading Failed",e.toString,"warning");
        });
    }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData"
  }
};
</script>