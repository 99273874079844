<template>
  <BaseContent activePath="/not-found">
    <template v-slot:header>
      <b>
        <i class="fa fa-battery-empty"></i> 404 - Not Found
      </b>
    </template>

    <b-card title="Not Found" class="w3-container">
      <p>Item Missing</p>
    </b-card>
  </BaseContent>
</template>

<script>
// @ is an alias to /src
import BaseContent from "@/components/BaseContent.vue";

export default {
  name: "NotFound",
  components: {
    BaseContent
  }
};
</script>