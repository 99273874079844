<template>
  <div>
    <b-form class="form-signin" @submit="onSubmit" @reset="onReset" v-if="show">
      <img class="mb-4" src="/docs/4.4/assets/brand/bootstrap-solid.svg" alt width="72" height="72" />
      <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
      <b-form-group id="input-group-1">
        <b-form-input
          id="input-consumer-key"
          class="form-control"
          v-model="form.consumer_key"
          type="text"
          required
          placeholder="Enter Consumer Key"
          autocomplete="off"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2">
        <b-form-input
          id="input-consumer-password"
          class="form-control"
          type="password"
          v-model="form.consumer_password"
          required
          placeholder="Enter Consumer Password"
          autocomplete="off"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">Submit</b-button> &nbsp;
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
  </div>
</template>

<style>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>

<script>
import axios from "axios";

import store from "../store";

export default {
  data() {
    return {
      form: {
        consumer_key: "",
        consumer_password: ""
      },
      show: true,
      response: "",
      errors: []
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      //save credentials
      store.setUsername(this.form.consumer_key);
      store.setPassword(this.form.consumer_password);

      //try access a resource
      axios
        .create({
          baseURL: store.baseURL,
          auth: {
            username: store.getUsername(),
            password: store.getPassword()
          }
        })
        .get("/api/v1/enums")
        .then(response => {
          this.response = response.data;
          store.setAuthenticated(true);
          //redirect to home
          this.$router.push({ path: "/" });
        })
        .catch(e => {
          this.errors.push(e);
          store.setAuthenticated(false);

          this.makeToast("Login Failed","Please check your credentials and try again","warning");
        });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.consumer_key = "";
      this.form.consumer_password = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    makeToast(title, content, variant = null) {
      this.$bvToast.toast(content, {
        toaster:"b-toaster-top-center",
        title,
        variant,
        solid: true
      });
    }
  }
};
</script>