<template>
  <div class="w3-light-grey">
    <!-- Top container -->
    <div class="w3-bar w3-top w3-black w3-large" style="z-index:4">
      <button
        class="w3-bar-item w3-button w3-hide-large w3-hover-none w3-hover-text-light-grey"
        onclick="w3_open();"
      >
        <i class="fa fa-bars"></i> &nbsp;Menu
      </button>
      <span class="w3-bar-item w3-right">
        <p>Simple.Fast.Reliable</p>
      </span>
    </div>

    <!-- Sidebar/menu -->
    <nav
      class="w3-sidebar w3-collapse w3-white w3-animate-left"
      style="z-index:3;width:300px;"
      id="mySidebar"
    >
      <br />
      <div class="w3-container w3-row">
        <div class="w3-col s4">
          <img src="@/assets/avatar2.png" class="w3-circle w3-margin-right" style="width:46px" />
        </div>
        <div class="w3-col s8 w3-bar">
          <span>
            Welcome,
            <strong>
              <slot name="user">{{username}}</slot>
            </strong>
          </span>
          <br />
          <a href="#" class="w3-bar-item w3-button" @click="logout()">
            <i class="fa fa-undo"></i> Logout
          </a>
        </div>
      </div>
      <hr />
      <div class="w3-container">
        <h5>
          <slot name="title">Menu</slot>
        </h5>
      </div>
      <div class="w3-bar-block">
        <a
          href="#"
          class="w3-bar-item w3-button w3-padding-16 w3-hide-large w3-dark-grey w3-hover-black"
          onclick="w3_close()"
          title="close menu"
        >
          <i class="fa fa-remove fa-fw"></i>&nbsp; Close Menu
        </a>
        <template v-for="(link, index) in navItems">
          <router-link :class="getNavClass(link)" :key="index" :to="link.path">
            <i :class="link.icon"></i>
            &nbsp; {{link.title}}
          </router-link>
        </template>

        <br />
        <br />
      </div>
    </nav>

    <!-- Overlay effect when opening sidebar on small screens -->
    <div
      class="w3-overlay w3-hide-large w3-animate-opacity"
      onclick="w3_close()"
      style="cursor:pointer"
      title="close side menu"
      id="myOverlay"
    ></div>

    <!-- !PAGE CONTENT! -->
    <div class="w3-main" style="margin-left:300px;margin-top:43px;">
      <!-- Header -->
      <header class="w3-container" style="padding-top:22px">
        <h5>
          <slot name="header"></slot>
        </h5>
      </header>

      <slot></slot>

      <!-- Footer -->
      <footer class="w3-container w3-padding-16 w3-light-grey">
        <h4>Dispatcher J</h4>
        <p>
          Powered by
          <a href="http://wrightinteractives.com" target="_blank">wright interactives</a>
        </p>
      </footer>

      <!-- End page content -->
    </div>
  </div>
</template>

<style>
@import "../assets/css/w3.css";
@import "../assets/css/raleway.css";
@import "../assets/css/fontawesome.css";
</style>

<script>
import store from "../store";

export default {
  name: "BaseContent",
  props: {
    activePath: String
  },
  computed: {
    username: function() {
      return store.getUsername();
    },
    navItems: function() {
      return store.getNavItems();
    }
  },
  methods: {
    getNavClass: function(link) {
      if (String(link.path) == String(this.activePath)) {
        return "w3-bar-item w3-button w3-padding w3-blue";
      } else {
        return "w3-bar-item w3-button w3-padding";
      }
    },
    logout: function() {
      store.setUsername("");
      store.setPassword("");
      store.setAuthenticated(false);
      //redirect to login
      this.$router.push({ path: "/login" });
    }
  }
};
</script>