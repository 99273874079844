<template>
  <BaseContent activePath="/about">
    <template v-slot:header>
      <b>
        <i class="fa fa-dashboard"></i> About
      </b>
    </template>

    <b-card title="DispatcherJ">
      <p>Dispatcher-J Management Portal</p>
    </b-card>
  </BaseContent>
</template>

<script>
// @ is an alias to /src
import BaseContent from "@/components/BaseContent.vue";

export default {
  name: "About",
  components: {
    BaseContent
  }
};
</script>