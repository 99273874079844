//var baseURL = "http://localhost:7000";
//var baseURL = "https://dispatcher.wrightinteractives.com/";
var baseURL = window.baseURL;

var store = {
    debug:false,
    baseURL: baseURL,
    state: {
      authenticated: false,
      username:'',
      password:''
    },
    setUsername (newValue) {
      // if (this.debug) console.log('setUsername triggered with', newValue)
      this.state.username = newValue
    },
    getUsername(){
      return this.state.username;
    },
    setPassword (newValue) {
      // if (this.debug) console.log('setPassword triggered with', newValue)
      this.state.password = newValue
    },
    getPassword(){
      return this.state.password;
    },
    setAuthenticated(newValue) {
      // if (this.debug) console.log('setAuthenticated triggered with', newValue)
      this.state.authenticated = newValue
    },
    isAuthenticated(){
        return this.state.authenticated;
    },
    getNavItems(){
      return [
        // {path:'/',icon:'fa fa-dashboard fa-fw',title:'Overview'},
        {path:'/clients',icon:'fa fa-users fa-fw',title:'Clients'},
        {path:'/paybills',icon:'fa fa-list fa-fw',title:'Paybills'},
        {path:'/disbursements',icon:'fa fa-bank fa-fw',title:'Disbursements'},
        {path:'/transactions',icon:'fa fa-history fa-fw',title:'Transactions'},
        {path:'/settings',icon:'fa fa-cog fa-fw',title:'Settings'},
        {path:'/about',icon:'fa fa-eye fa-fw',title:'About'},
      ];
    }
  }

  export default store
