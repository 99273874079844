<template>
  <BaseContent activePath="/clients">
    <template v-slot:header>
      <b>
        <i class="fa fa-users"></i> Client
      </b>
    </template>

    <div v-if="loading" class="loading">Loading...</div>

    <div v-if="error" class="error">{{ error }}</div>

    <b-card :title="'Client: '+ (this.form.client_name!=null?this.form.client_name:'')">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-client_name"
              label="Client:"
              label-for="input-client_name"
            >
              <b-form-input
                id="input-client_name"
                v-model="form.client_name"
                type="text"
                required
                placeholder="Enter Client"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-consumer_key"
              label="Consumer Key:"
              label-for="input-consumer_key"
            >
              <b-form-input
                id="input-consumer_key"
                v-model="form.consumer_key"
                type="text"
                readonly
                required
                placeholder="Enter Consumer Key"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-consumer_secret"
              label="API Consumer Secret:"
              label-for="input-consumer_secret"
            >
              <b-form-input
                id="input-consumer_secret"
                v-model="form.consumer_secret"
                type="text"
                readonly
                required
                placeholder="Enter Consumer Secret"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-allowed_transaction_types"
              label="Transaction Types:"
              label-for="input-allowed_transaction_types"
            >
              <b-form-checkbox-group
                id="input-allowed_transaction_types"
                v-model="form.allowed_transaction_types"
                :options="transaction_types"
                placeholder="Enter Transaction Types"
              ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-client_status"
              label="Status:"
              label-for="input-client_status"
            >
              <b-form-select
                id="input-client_status"
                v-model="form.client_status"
                :options="statuses"
                required
                placeholder="Enter Status"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-button type="submit" variant="primary">Submit</b-button>&nbsp;
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-form>
    </b-card>

    <b-card title="Client Paybills:">
      <b-form inline>
        <label class="sr-only" for="inline-form-input-api-name">Paybill Application Name</label>
        <b-input
          id="inline-form-input-api-name"
          v-model="api_name"
          list="inline-form-input-api-name-list"
          class="mb-2 mr-sm-2 mb-sm-0"
          placeholder="Application Name"
        ></b-input>
        <datalist id="inline-form-input-api-name-list">
          <option v-for="(paybill,index) in paybills" :key="index">{{ paybill }}</option>
        </datalist>
        <b-button variant="primary" @click="addClientPaybill">Add</b-button>
      </b-form>

      <hr />

      <b-table
        hover
        :items="client_paybills"
        :fields="fields"
        :per-page="100"
        small
        striped
        responsive="sm"
      >
        <template v-slot:cell(actions)="row">
          <b-button
            size="sm"
            @click="toggleStatus(row)"
          >{{ row.item.client_paybill_status=='ACTIVE' ? 'Disable' : 'Enable' }}</b-button>
        </template>
      </b-table>
    </b-card>
  </BaseContent>
</template>

<script>
// @ is an alias to /src
import BaseContent from "@/components/BaseContent.vue";

import axios from "axios";

import store from "@/store";

export default {
  name: "Client",
  props: ["client_id"],
  components: {
    BaseContent
  },
  data() {
    return {
      form: {
        // client_id: null,
        client_type: "CONSUMER",
        // client_name: null,
        // consumer_key: null,
        // consumer_secret: null,
        allowed_transaction_types: [],
        // unlock_time: null,
        // lock_status: null,
        client_status: null
        // record_version: null
      },
      client_paybills: null,
      show: true,
      loading: false,
      response: null,
      error: null,
      transaction_types: [
        { value: "B2C", text: "B2C" },
        { value: "C2B", text: "C2B" },
        { value: "STK", text: "STK" },
        { value: "BPB", text: "BPB" },
        { value: "B2B", text: "B2B" },
        { value: "BBG", text: "BBG" },
        { value: "BAL", text: "BAL" },
        { value: "DF2B", text: "DF2B" },
        { value: "BTFM2U", text: "BTFM2U" },
        { value: "BTFU2M", text: "BTFU2M" },
        { value: "IMT", text: "IMT" },
        { value: "M2MT", text: "M2MT" },
        { value: "MTFM2W", text: "MTFM2W" },
        { value: "MSMMFAT", text: "MSMMFAT" },
        { value: "ORS", text: "ORS" },
      ],
      statuses: [
        { value: null, text: "-select one-" },
        { value: "ACTIVE", text: "ACTIVE" },
        { value: "IN-ACTIVE", text: "IN-ACTIVE" }
      ],
      fields: [
        { key: "setting_profile" },
        { key: "api_application_name" },
        { key: "shortcode_b2c" },
        { key: "shortcode_c2b" },
        { key: "client_paybill_status" },
        { key: "actions" }
      ],
      api_name: "",
      paybills: []
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      alert(JSON.stringify(this.form));

      if (this.client_id != null || this.client_id != undefined) {
        //save
        axios
          .create({
            baseURL: store.baseURL,
            auth: {
              username: store.getUsername(),
              password: store.getPassword()
            }
          })
          .put("/api/v1/clients/" + this.client_id, this.form)
          .then(response => {
            this.response = response.data;
            this.loading = false;

            this.applyData();

            this.makeToast("Saved", "Client Saved", "success");
          })
          .catch(e => {
            //missing setting, display error?
            this.error = e.toString();
            this.makeToast("Save Failed", "Client not Saved", "warning");
          });
      } else {
        //save
        axios
          .create({
            baseURL: store.baseURL,
            auth: {
              username: store.getUsername(),
              password: store.getPassword()
            }
          })
          .post("/api/v1/clients", this.form)
          .then(response => {
            this.response = response.data;
            this.loading = false;

            this.applyData();

            this.makeToast("Saved", "Client Saved", "success");
          })
          .catch(e => {
            //missing setting, display error?
            this.error = e.toString();
            this.makeToast("Save Failed", "Client not Saved", "warning");
          });
      }
    },
    onReset(evt) {
      evt.preventDefault();

      // Reset our form values
      this.applyData();

      this.api_name = "";

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    applyData() {
      if (this.response != null || this.response != undefined) {
        this.form.client_id = this.response.resource.client_id;
        this.form.client_type = this.response.resource.client_type;
        this.form.client_name = this.response.resource.client_name;
        this.form.consumer_key = this.response.resource.consumer_key;
        this.form.consumer_secret = this.response.resource.consumer_secret;
        this.form.allowed_transaction_types = this.response.resource.allowed_transaction_types.split(
          ","
        );
        this.form.lock_status = this.response.resource.lock_status;
        this.form.client_status = this.response.resource.client_status;
        this.form.record_version = this.response.resource.record_version;

        this.client_paybills = this.response.resource.client_paybills;
      } else {
        this.client_paybills = null;
      }
    },
    makeToast(title, content, variant = null) {
      this.$bvToast.toast(content, {
        toaster: "b-toaster-top-center",
        title,
        variant,
        solid: true
      });
    },
    fetchData() {
      if (this.client_id != null || this.client_id != undefined) {
        this.api_name = "";
        this.error = this.response = null;
        this.loading = true;
        //try access a resource
        axios
          .create({
            baseURL: store.baseURL,
            auth: {
              username: store.getUsername(),
              password: store.getPassword()
            }
          })
          .get("/api/v1/clients/" + this.client_id)
          .then(response => {
            this.response = response.data;
            this.loading = false;

            this.applyData();
          })
          .catch(e => {
            //missing setting, display error?
            this.error = e.toString();

            this.makeToast("Loading Failed", e.toString, "warning");
          });
      }
    },
    toggleStatus(row) {
      axios
        .create({
          baseURL: store.baseURL,
          auth: {
            username: store.getUsername(),
            password: store.getPassword()
          }
        })
        .put(
          "/api/v1/client/paybills/" +
            this.client_id +
            "/" +
            row.item.client_paybill_id,
          {
            client_paybill_id: parseInt(row.item.client_paybill_id),
            client_paybill_status:
              String(row.item.client_paybill_status) == "ACTIVE"
                ? "IN-ACTIVE"
                : "ACTIVE",
            record_version: parseInt(row.item.record_version)
          }
        )
        .then(response => {
          this.response = response.data;
          this.loading = false;

          this.makeToast("Saved", "Client Paybill Saved", "success");

          this.fetchData();
        })
        .catch(e => {
          //missing setting, display error?
          this.error = e.toString();
          this.makeToast("Save Failed", "Client Paybill not Saved", "warning");
        });
    },
    addClientPaybill() {
      if (
        (this.client_id != null || this.client_id != undefined) &&
        this.api_name != ""
      ) {
        //save
        axios
          .create({
            baseURL: store.baseURL,
            auth: {
              username: store.getUsername(),
              password: store.getPassword()
            }
          })
          .post("/api/v1/client/paybills/" + this.client_id, {
            api_application_name: this.api_name,
            client_id: parseInt(this.client_id),
            client_paybill_status: "ACTIVE"
          })
          .then(response => {
            this.response = response.data;
            this.loading = false;

            this.makeToast("Saved", "Client Paybill Saved", "success");

            this.fetchData();
          })
          .catch(e => {
            //missing setting, display error?
            this.error = e.toString();
            this.makeToast(
              "Save Failed",
              "Client Paybill not Saved",
              "warning"
            );
          });
      }
    },
    loadPaybills() {
      axios
        .create({
          baseURL: store.baseURL,
          auth: {
            username: store.getUsername(),
            password: store.getPassword()
          }
        })
        .get(
          "/api/v1/paybills?all=true&paybillstatus=ACTIVE&columns=api_application_name"
        )
        .then(response => {
          this.loading = false;

          response.data.results.forEach(element => {
            this.paybills.push(element.api_application_name);
          });
        })
        .catch(e => {
          //missing setting, display error?
          this.error = e.toString();

          this.makeToast("Loading Failed", e.toString, "warning");
        });
    }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
    this.loadPaybills();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData"
  }
};
</script>