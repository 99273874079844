<template>
  <BaseContent activePath="/transactions">
    <template v-slot:header>
      <b>
        <i class="fa fa-history"></i> Transactions
      </b>
    </template>

    <b-container fluid>
      <!-- User Interface controls -->
      <b-row>
        <b-col sm="6" md="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Type to Search"
                debounce="500"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="6" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>

      <b-card title="Transactions">
        <b-table
          hover
          :items="dataProvider"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction.sync="sortDirection"
          @filtered="onFiltered"
          small
          striped
          responsive="sm"
        >          
        </b-table>
      </b-card>
    </b-container>
  </BaseContent>
</template>

<script>
// @ is an alias to /src
import BaseContent from "@/components/BaseContent.vue";

import axios from "axios";

import store from "@/store";

export default {
  name: "Transactions",
  data() {
    return {
      fields: [
        { key: "transaction_number", sortable: true },
        { key: "other_ref", sortable: true },
        { key: "mpesa_ref", sortable: true },
        { key: "source_paybill", sortable: true },
        { key: "transaction_type", sortable: true },
        { key: "receiver", sortable: true },
        { key: "sent_amount", sortable: true },
        { key: "result_code", sortable: false },
        { key: "result_description", sortable: false },
        { key: "transaction_status", sortable: true },
      ],
      rows: [],
      totalRows: 100,
      currentPage: 1,
      perPage: 10,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      }
    };
  },
  methods: {
    dataProvider: function(ctx) {
      const promise = axios
        .create({
          baseURL: store.baseURL,
          auth: {
            username: store.getUsername(),
            password: store.getPassword()
          }
        })
        .get(
          "/api/v1/transactions?offset=" +
            (ctx.currentPage - 1) +
            "&page_size=" +
            ctx.perPage +
            (ctx.filter != null ? "&q=" + ctx.filter : "") +
            (String(ctx.sortBy) != String("")
              ? "&sort=" +
                ctx.sortBy +
                "&sortd=" +
                (ctx.sortDesc ? "desc" : "asc")
              : "&sort=transaction_id&sortd=desc")
        );

      // Must return a promise that resolves to an array of items
      return promise.then(data => {
        // Pluck the array of items off our axios response
        const items = data.data.results;
        // Must return an array of items or an empty array if an error occurred

        this.rows = items || [];

        return items || [];
      });
    },
    getEditPath(item) {
      return "/transaction/" + item.transaction_id;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = 100 || filteredItems.length;
      //this.currentPage = 1;
    }
  },
  components: {
    BaseContent
  }
};
</script>