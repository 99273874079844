import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/About.vue'
import Login from '../views/Login.vue'
import Clients from '../views/Clients.vue'
import Client from '../views/Client.vue'
import Paybills from '../views/Paybills.vue'
import Paybill from '../views/Paybill.vue'
import Disbursements from '../views/Disbursements.vue'
import Transactions from '../views/Transactions.vue'
import Settings from '../views/Settings.vue'
import Setting from '../views/Setting.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/clients',
    name: 'clients',
    component: Clients
  },
  {
    path: '/client',
    name: 'add client',
    component: Client,
    props: true
  },
  {
    path: '/client/:client_id',
    name: 'edit client',
    component: Client,
    props: true
  },
  {
    path: '/paybills',
    name: 'paybills',
    component: Paybills
  },
  {
    path: '/paybill',
    name: 'add paybill',
    component: Paybill,
    props: true
  },
  {
    path: '/paybill/:paybill_id',
    name: 'edit paybill',
    component: Paybill,
    props: true
  },
  {
    path: '/disbursements',
    name: 'disbursements',
    component: Disbursements
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: Transactions
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings
  },
  {
    path: '/setting/:setting_id',
    name: 'edit setting',
    component: Setting,
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFound
  },
]

const router = new VueRouter({
  routes
})

// GOOD
router.beforeEach((to, from, next) => {
  if (to.path.valueOf()!=="/login".valueOf() && !store.isAuthenticated()) next('/login')
  else next()
})

export default router
