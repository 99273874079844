<template>
  <BaseContent activePath="/paybills">
    <template v-slot:header>
      <b>
        <i class="fa fa-list"></i> Paybill
      </b>
    </template>

    <div v-if="loading" class="loading">Loading...</div>

    <div v-if="error" class="error">{{ error }}</div>

    <b-card
      :title="'Paybill: '+ (this.form.api_application_name!=null?this.form.api_application_name:'')"
    >
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-setting_profile"
              label="Profile:"
              label-for="input-setting_profile"
            >
              <b-form-input
                id="input-setting_profile"
                v-model="form.setting_profile"
                type="text"
                required
                placeholder="Enter Profile"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-paybill_type"
              label="Paybill Type:"
              label-for="input-paybill_type"
            >
              <b-form-select
                id="input-paybill_type"
                v-model="form.paybill_type"
                :options="paybill_types"
                required
                placeholder="Enter Paybill Type"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-api_application_name"
              label="Application Name:"
              label-for="input-api_application_name"
            >
              <b-form-input
                id="input-api_application_name"
                v-model="form.api_application_name"
                type="text"
                required
                placeholder="Enter Application Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-api_api_consumer_key"
              label="API Consumer Key:"
              label-for="input-api_consumer_key"
            >
              <b-form-input
                id="input-api_api_consumer_key"
                v-model="form.api_consumer_key"
                type="text"
                required
                placeholder="Enter API Consumer Key"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-api_consumer_secret"
              label="API Consumer Secret:"
              label-for="input-api_consumer_secret"
            >
              <b-form-input
                id="input-api_consumer_secret"
                v-model="form.api_consumer_secret"
                type="text"
                required
                placeholder="Enter API Consumer Secret"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="form.paybill_type=='B2C'">
            <b-form-group
              id="input-group-initiator_identifier"
              label="API Initiator:"
              label-for="input-initiator_identifier"
            >
              <b-form-input
                id="input-initiator_identifier"
                v-model="form.initiator_identifier"
                type="text"
                
                placeholder="Enter API Initiator"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row v-if="form.paybill_type=='B2C'">
          <b-col>
            <b-form-group
              id="input-group-initiator_security_credential"
              label="API Initiator Security Credentials:"
              label-for="input-initiator_security_credential"
            >
              <b-form-textarea
                id="input-initiator_security_credential"
                v-model="form.initiator_security_credential"
                type="text"
                
                placeholder="Enter API Initiator Security Credentials"
                rows="5"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-shortcode_b2c"
              label="B2C Shortcode:"
              label-for="input-shortcode_b2c"
            >
              <b-form-input
                id="input-shortcode_b2c"
                v-model="form.shortcode_b2c"
                type="text"
                
                placeholder="Enter B2C Shortcode"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row v-if="form.paybill_type=='C2B'">
          <b-col>
            <b-form-group
              id="input-group-shortcode_c2b"
              label="C2B Shortcode:"
              label-for="input-shortcode_c2b"
            >
              <b-form-input
                id="input-shortcode_c2b"
                v-model="form.shortcode_c2b"
                type="text"
                
                placeholder="Enter C2B Shortcode"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-shortcode_online"
              label="STK Shortcode:"
              label-for="input-shortcode_online"
            >
              <b-form-input
                id="input-shortcode_online"
                v-model="form.shortcode_online"
                type="text"
                
                placeholder="Enter STK Shortcode"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col v-if="form.paybill_type=='C2B'">
            <b-form-group
              id="input-group-onlinepasskey"
              label="STK Passkey:"
              label-for="input-onlinepasskey"
            >
              <b-form-textarea
                id="input-onlinepasskey"
                v-model="form.onlinepasskey"
                type="text"
                
                placeholder="Enter STK Passkey"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-paybill_status"
              label="Status:"
              label-for="input-paybill_status"
            >
              <b-form-select
                id="input-paybill_status"
                v-model="form.paybill_status"
                :options="statuses"
                required
                placeholder="Enter Status"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-button type="submit" variant="primary">Submit</b-button>&nbsp;
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-form>
    </b-card>
  </BaseContent>
</template>

<script>
// @ is an alias to /src
import BaseContent from "@/components/BaseContent.vue";

import axios from "axios";

import store from "@/store";

export default {
  name: "Paybill",
  props: ["paybill_id"],
  components: {
    BaseContent
  },
  data() {
    return {
      form: {
        paybill_id: null,
        setting_profile: null,
        paybill_type: null,
        api_application_name: null,
        api_consumer_key: null,
        api_consumer_secret: null,
        initiator_identifier_type: "11",
        initiator_identifier: null,
        initiator_security_credential: null,
        shortcode_b2c: null,
        shortcode_c2b: null,
        shortcode_online: null,
        onlinepasskey: null,
        paybill_status: null,
        record_version: null
      },
      show: true,
      loading: false,
      response: null,
      error: null,
      paybill_types: [
          { value: null, text: '-select one-' },
          { value: 'B2C', text: 'B2C' },
          { value: 'C2B', text: 'C2B' }
        ],
      statuses: [
          { value: null, text: '-select one-' },
          { value: 'ACTIVE', text: 'ACTIVE' },
          { value: 'IN-ACTIVE', text: 'IN-ACTIVE' }
        ]
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      if (this.paybill_id != null || this.paybill_id != undefined) {
        //save
        axios
          .create({
            baseURL: store.baseURL,
            auth: {
              username: store.getUsername(),
              password: store.getPassword()
            }
          })
          .put("/api/v1/paybills/" + this.paybill_id, this.form)
          .then(response => {
            this.response = response.data;
            this.loading = false;

            this.form.paybill_id = this.response.resource.paybill_id;
            this.form.setting_profile = this.response.resource.setting_profile;
            this.form.paybill_type = this.response.resource.paybill_type;
            this.form.api_application_name = this.response.resource.api_application_name;
            this.form.api_consumer_key = this.response.resource.api_consumer_key;
            this.form.api_consumer_secret = this.response.resource.api_consumer_secret;
            this.form.initiator_identifier_type = this.response.resource.initiator_identifier_type;
            this.form.initiator_identifier = this.response.resource.initiator_identifier;
            this.form.initiator_security_credential = this.response.resource.initiator_security_credential;
            this.form.shortcode_b2c = this.response.resource.shortcode_b2c;
            this.form.shortcode_c2b = this.response.resource.shortcode_c2b;
            this.form.shortcode_online = this.response.resource.shortcode_online;
            this.form.onlinepasskey = this.response.resource.onlinepasskey;
            this.form.paybill_status = this.response.resource.paybill_status;
            this.form.record_version = this.response.resource.record_version;

            this.makeToast("Saved", "Paybill Saved", "success");
          })
          .catch(e => {
            //missing setting, display error?
            this.error = e.toString();
            this.makeToast("Save Failed", "Paybill not Saved", "warning");
          });
      } else {
        //save
        axios
          .create({
            baseURL: store.baseURL,
            auth: {
              username: store.getUsername(),
              password: store.getPassword()
            }
          })
          .post("/api/v1/paybills", this.form)
          .then(response => {
            this.response = response.data;
            this.loading = false;

            this.form.paybill_id = this.response.resource.paybill_id;
            this.form.setting_profile = this.response.resource.setting_profile;
            this.form.paybill_type = this.response.resource.paybill_type;
            this.form.api_application_name = this.response.resource.api_application_name;
            this.form.api_consumer_key = this.response.resource.api_consumer_key;
            this.form.api_consumer_secret = this.response.resource.api_consumer_secret;
            this.form.initiator_identifier_type = this.response.resource.initiator_identifier_type;
            this.form.initiator_identifier = this.response.resource.initiator_identifier;
            this.form.initiator_security_credential = this.response.resource.initiator_security_credential;
            this.form.shortcode_b2c = this.response.resource.shortcode_b2c;
            this.form.shortcode_c2b = this.response.resource.shortcode_c2b;
            this.form.shortcode_online = this.response.resource.shortcode_online;
            this.form.onlinepasskey = this.response.resource.onlinepasskey;
            this.form.paybill_status = this.response.resource.paybill_status;
            this.form.record_version = this.response.resource.record_version;

            this.makeToast("Saved", "Paybill Saved", "success");
          })
          .catch(e => {
            //missing setting, display error?
            this.error = e.toString();
            this.makeToast("Save Failed", "Paybill not Saved", "warning");
          });
      }
    },
    onReset(evt) {
      evt.preventDefault();

      // Reset our form values
      this.form.paybill_id = this.response.resource.paybill_id;
      this.form.setting_profile = this.response.resource.setting_profile;
      this.form.paybill_type = this.response.resource.paybill_type;
      this.form.api_application_name = this.response.resource.api_application_name;
      this.form.api_consumer_key = this.response.resource.api_consumer_key;
      this.form.api_consumer_secret = this.response.resource.api_consumer_secret;
      this.form.initiator_identifier_type = this.response.resource.initiator_identifier_type;
      this.form.initiator_identifier = this.response.resource.initiator_identifier;
      this.form.initiator_security_credential = this.response.resource.initiator_security_credential;
      this.form.shortcode_b2c = this.response.resource.shortcode_b2c;
      this.form.shortcode_c2b = this.response.resource.shortcode_c2b;
      this.form.shortcode_online = this.response.resource.shortcode_online;
      this.form.onlinepasskey = this.response.resource.onlinepasskey;
      this.form.paybill_status = this.response.resource.paybill_status;
      this.form.record_version = this.response.resource.record_version;

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    makeToast(title, content, variant = null) {
      this.$bvToast.toast(content, {
        toaster: "b-toaster-top-center",
        title,
        variant,
        solid: true
      });
    },
    fetchData() {
      if (this.paybill_id != null || this.paybill_id != undefined) {
        this.error = this.response = null;
        this.loading = true;
        //try access a resource
        axios
          .create({
            baseURL: store.baseURL,
            auth: {
              username: store.getUsername(),
              password: store.getPassword()
            }
          })
          .get("/api/v1/paybills/" + this.paybill_id)
          .then(response => {
            this.response = response.data;
            this.loading = false;

            this.form.paybill_id = this.response.resource.paybill_id;
            this.form.setting_profile = this.response.resource.setting_profile;
            this.form.paybill_type = this.response.resource.paybill_type;
            this.form.api_application_name = this.response.resource.api_application_name;
            this.form.api_consumer_key = this.response.resource.api_consumer_key;
            this.form.api_consumer_secret = this.response.resource.api_consumer_secret;
            this.form.initiator_identifier_type = this.response.resource.initiator_identifier_type;
            this.form.initiator_identifier = this.response.resource.initiator_identifier;
            this.form.initiator_security_credential = this.response.resource.initiator_security_credential;
            this.form.shortcode_b2c = this.response.resource.shortcode_b2c;
            this.form.shortcode_c2b = this.response.resource.shortcode_c2b;
            this.form.shortcode_online = this.response.resource.shortcode_online;
            this.form.onlinepasskey = this.response.resource.onlinepasskey;
            this.form.paybill_status = this.response.resource.paybill_status;
            this.form.record_version = this.response.resource.record_version;
          })
          .catch(e => {
            //missing setting, display error?
            this.error = e.toString();

            this.makeToast("Loading Failed", e.toString, "warning");
          });
      }
    }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData"
  }
};
</script>